//实时单 路由
export default [

	//货源部分
	{
		path:'transit_real_time/cargo_creat_admin',
		component(){
			return import('@/pages/transit_real_time/cargo/creat.vue')
		}
	},{
		path:'transit_real_time/cargo_list_admin',
		component(){
			return import('@/pages/transit_real_time/cargo/list.vue')
		}
	},{
		path:'transit_real_time/cargo_edit_admin',
		component(){
			return import('@/pages/transit_real_time/cargo/edit.vue')
		}
	},


	//运单相关
	{
		path:'transit_real_time/tord_check',
		//role:['开发者'],
		component(){
			return import('@/pages/transit_real_time/tord/check.vue')
		}
	},
	{
		path:'transit_real_time/tord_deal',
		component(){
			return import('@/pages/transit_real_time/tord/deal.vue')
		}
	},
	{
		path:'transit_real_time/tord_act_add',
		component(){
			return import('@/pages/transit_real_time/tord/creat.vue')
		}
	},{
		path:'transit_real_time/tord_act_list',
		component(){
			return import('@/pages/transit_real_time/tord/act.vue')
		}
	},
	{
		path:'transit_real_time/tord_arrived_list',
		component(){
			return import('@/pages/transit_real_time/tord/arrived.vue')
		}
	},
	{
		path:'transit_real_time/tord_end_list',
		component(){
			return import('@/pages/transit_real_time/tord/end.vue')
		}
	},
	{
		path:'transit_real_time/tord_search_list',
		component(){
			return import('@/pages/transit_real_time/tord/search.vue')
		}
	},
	//更新待审核运单页面
	// {
	// 	path:'transit_real_time/waiting',
	// 	component(){
	// 		return import('@/pages/transit_real_time/tord/waiting.vue')
	// 	}
	// },
	//财务相关
	{
		path:'transit_real_time/tord_pay_list_window',
		component(){
			return import('@/pages/transit_real_time/finance/tord_pay_list_window.vue')
		}
	},{
		path:'transit_real_time/tord_pay_list',
		component(){
			return import('@/pages/transit_real_time/finance/tord_pay_list.vue')
		}
	},{
		path:'transit_real_time/tord_invoice_list',
		component(){
			return import('@/pages/transit_real_time/finance/tord_invoice_list.vue')
		}
	},{
		path:'transit_real_time/tord_invoice_list_new',
		component(){
			return import('@/pages/transit_real_time/finance/tord_invoice_list_new.vue')
		}
	},{
		path:'transit_real_time/tord_settle_list',
		component(){
			return import('@/pages/transit_real_time/finance/tord_settle_list.vue')
		}
	},{
		path:'transit_real_time/tord_settle_list_new',
		component(){
			return import('@/pages/transit_real_time/finance/tord_settle_list_new.vue')
		}
	},{
		path:'transit_real_time/tord/maptrajectory',
		component(){
			return import('@/pages/transit_real_time/tord/maptrajectory.vue')
		}
	},
]