//财务相关
export default [
	
	//提现相关
	{
		path:'finance/tord_real_time_outcash_list',
		component(){
			return import('@/pages/finance/out_cash/tord_outcash_list.vue')
		}
	},

	
	//充值相关
	{
		path:'finance/recharge',
		component(){
			return import('@/pages/finance/recharge/recharge.vue')
		}
	},{
		path:'finance/recharge_list_admin',
		component(){
			return import('@/pages/finance/recharge/recharge_list_admin.vue')
		}
	},
	{
		path:'finance/chake_list',
		component(){
			return import('@/pages/finance/chake_list/list.vue')
		}
	},
	
	
	//平台银行卡管理
	{
		path:'finance/platform_bankcard_list',
		component(){
			return import('@/pages/finance/platform_bankcard/list.vue')
		}
	},
	
	//提现相关
	{
		path:'finance/hsbank_refund',
		component(){
			return import('@/pages/finance/out_cash/hsbank_refund.vue')
		}
	},
	
	//打款异常/打款失败记录
	{
		path:'finance/out_cash_failed',
		component(){
			return import('@/pages/finance/out_cash_failed/ra_list.vue')
		}
	},
]