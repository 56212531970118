import Vue from 'vue'

//加载工具包
import my from './my'
Vue.prototype.$my = my;

//阻止生产模式的消息
Vue.config.productionTip = false

//element-ui
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI)

//element-ui patche
import patche from './patche'

//路由器配置
import router from 'vue-router'
Vue.use(router);                            //挂载路由
import router_config from './router'        //加载路由全局配置
const new_router=new router(router_config); //创建路由
new_router.beforeEach((to,from,next)=>{     //创建路由守卫
    my.routerBeforeEach(to,from,next)
})

//启动项目
const app = new Vue({
    router:new_router,
    render: h => h('router-view')
}).$mount('#app')


//骚操作
Vue.prototype.$my.install(app)