const my={

	//md5(方法)
	md5:require('md5'),

	//base64
	base64:require('./base64.js').default,

	//各种校验
	check:require('./check.js').default,

	//其他工具
	other:require('./other.js').default,

	//快捷缓存(对象)
	cache:require('./cache.js').default,
	
	//rsa(对象)
	rsa:require('./rsa.js').default,

	//网络板块
	net:require('./net.js').default,

	//七牛
	qiniu:require('./qiniu.js').default,

	//路由信息
	//router:require('../router').default
};

//不得而为之 -> 操蛋的js
my.other.__parent=my
my.cache.__parent=my
my.rsa.__parent=my
my.net.__parent=my
my.qiniu.__parent=my
my.install=function(app){
	this.__app=app;
	delete(this.install)
}

//未初始化前给cache放置初始值(此时绝对没有初始化完成 更不可能登录完成)
my.cache.set({
	is_login:2,		//登陆状态(1:已登录,2:未登录)
	init_status:1,	//初始化状态(1:未初始化,2:初始化中,3:已初始化成功,4:初始化失败)
	bucket:{},		//七牛的bucket
	user_info:{},	//用户信息
	session_id:''
});
//读取用户缓存权限
//路由守卫
my.routerBeforeEach=function(to,from,next){
	let app_my=this;
	// console.log(app_my.router,'路由信息');
	//初始化状态
	let init_status=app_my.cache.get('init_status');
	//console.log(init_status)

	//未开始初始化
	if(init_status==1){

		//开始初始化
		init({

			//作用域
			that:app_my,

			//初始化完成
			success:(data)=>{
				//已登录
				if(data.is_login==1){
				   // 读取用户缓存
					// let user_info = app_my.cache.get("user_info");
					// let route = user_info.ug_name
					// console.log(route);
					// var a = app_my.router.routes[2].children[30].role
					// if(a[0] == route){
					// 	console.log('对了');
					// 	next()
					// }else{
					// 	console.log('不对');
					// 	next(false)
					// }
					//走你
					next()

				}else if(data.is_login==2){//未登录
					//去哪里?
					if(to.name!='login'){
						//缓存本来要去的页面地址
						app_my.cache.set({
							_before_login_to_page:{
								fullPath:to.fullPath,
								
							}
						});

						//前往登录页
						next('/login')
					}else{
						//走你
						next()
					}
				}
			},
			faild:(res)=>{
				
				app_my.other.msg({
					type:'error',
					str:'初始化失败'
				});
			}
		})
	}else if(init_status==2){//初始化中
		// let user_info = app_my.cache.get("user_info");
		// console.log(user_info,'用户信息2');
		//缓存要去的页面
		if(to.name!='login'){

			app_my.cache.set({
				_before_login_to_page:{
					fullPath:to.fullPath,

				}
			});
		}
	}else if(init_status==3){//已经初始化
		
		//登录状态
		let is_login=this.cache.get('is_login');

		//console.log(is_login)

		//已登录
		if(is_login==1){
			let user_info = app_my.cache.get("user_info");
			let route = user_info.menu_list
			//走你
			next()
		}else if(is_login==2){//未登录
			
			//去哪里?
			if(to.name!='login'){

				//缓存要去的页面
				app_my.cache.set({
					_before_login_to_page:{
						fullPath:to.fullPath,

					}
				});
				
				//前往登陆页面
				next('/login')
			}else{

				//走你
				next();
			}
		}
	}
}

let init=function(obj){

	let app_my=obj.that

	//尝试从硬盘读取session_id
	let session_id=localStorage.getItem('__apple__mac__windows__set__');
	if(session_id){
		app_my.cache.set({
			session_id:session_id
		});
	}

	//调用初始化接口
	app_my.net.req({
		data:{
			mod:'init',
			ctr:'transit_admin_webpage_init',
		},
		take_over_control:2,//强制接管(1:接管content,2:接管整个请求返回,无此参数:不接管任何数据)
		callback:(res)=>{
			
			/*  初始化失败  */
			//网络传输失败
			if(res.status!=200){

				//保存数据
				app_my.cache.set({
					init_status:4,
				});

				//初始化失败处理
				obj.faild();
				return;
			}
			//网络传输成功 后端逻辑层返回失败
			if(res.data.code!=0){

				//保存数据
				app_my.cache.set({
					init_status:4,
				});

				//初始化失败处理
				obj.faild();
				return;
			}


			/*  初始化成功  */
			//保存数据
			app_my.cache.set({
				session_id:res.data.msg.session_id,
				agent_para:res.data.msg.agent_para,
				user_info:res.data.msg.user_info,
				is_login:res.data.msg.is_login,
				bucket:res.data.msg.bucket,
				init_status:3,
			});

			//保存session_id
			localStorage.setItem('__apple__mac__windows__set__',res.data.msg.session_id)

			//初始化成功处理
			obj.success(res.data.msg);
		}
	});
}
export default my