//监管平台相关操作
export default [
	
	//上报司机
	{
		path:'transit_logink/driver_list_admin',
		component(){
			return import('@/pages/transit_logink/driver/list.vue')
		}
	},


	//上报货车
	{
		path:'transit_logink/truck_list_admin',
		component(){
			return import('@/pages/transit_logink/truck/list.vue')
		}
	},


	//上报实时单
	{
		path:'transit_logink/tord_real_time_admin',
		component(){
			return import('@/pages/transit_logink/tord_real_time/tord_list/list.vue')
		}
	},
	{//实时单支付流水
		path:'transit_logink/tord_real_time_payed_record',
		component(){
			return import('@/pages/transit_logink/tord_real_time/tord_payed_record/list.vue')
		}
	},


	//上报后录单
	{
		path:'transit_logink/tord_after_time_admin',
		component(){
			return import('@/pages/transit_logink/tord_after_time/tord_list/list.vue')
		}
	},
	{//后录单支付流水
		path:'transit_logink/tord_after_time_payed_record',
		component(){
			return import('@/pages/transit_logink/tord_after_time/tord_payed_record/list.vue')
		}
	},
]