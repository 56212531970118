/* 缓存组件 */

const cache = {
	bucket:{},
	act:{
		get(para){
			if(this.__parent.check.is_arr(para)){
				
				let out={};
				
				for(var key of para){
					
					if(!this.__parent.check.is_str(key)){
						this.__parent.other.msg("批量读取缓存时传入的每个键名必须是字符串")
						return;
					}
					
					out[key]=cache.bucket[key];
					
				}
				
				return {...out};
				
			}else if(this.__parent.check.is_str(para)){
				
				return cache.bucket[para];
			}
		},
		set(obj){
			
			if(!this.__parent.check.is_obj(obj)){
				this.__parent.other.msg("写入缓存时必须传入对象")
				return;
			}
			
			for(var key in obj){
				cache.bucket[key]=obj[key]
			}
		}
	}
}

export default cache.act