//货车管理 路由
export default [
	
	//货车类型
	{
		path:'truck/truck_type',
		component(){
			return import('@/pages/truck/truck_type/list.vue')
		}
	},
	

	//货车列表
	{
		path:'truck/truck_add',
		component(){
			return import('@/pages/truck/truck_list/truck_add.vue')
		}
	},{
		path:'truck/truck_list_before_check',
		component(){
			return import('@/pages/truck/truck_list/before_check.vue')
		}
	},{
		path:'truck/truck_list_checked_success',
		component(){
			return import('@/pages/truck/truck_list/checked_success.vue')
		}
	},{
		path:'truck/truck_list_checked_faild',
		component(){
			return import('@/pages/truck/truck_list/checked_faild.vue')
		}
	},


	//准驾车型
	{
		path:'truck/driver_class',
		component(){
			return import('@/pages/truck/driver_class/list.vue')
		}
	},


	//司机部分
	{
		path:'truck/driver_add',
		component(){
			return import('@/pages/truck/driver_list/driver_add.vue')
		}
	},{
		path:'truck/driver_list',
		component(){
			return import('@/pages/truck/driver_list/driver_list.vue')
		}
	},{
		path:'truck/bind_list',
		component(){
			return import('@/pages/truck/driver_list/bind_list.vue')
		}
	},
]