export default [{
		path:'admin/menu_list',
		component(){
			return import('@/pages/admin/developer/menu_list.vue')
		}
	},{
		path:'admin/api_test',
		component(){
			return import('@/pages/admin/developer/api_test.vue')
		}
	},{
		path:'admin/db_comparison',
		component(){
			return import('@/pages/admin/developer/db_comparison.vue')
		}
	},{
		path:'admin/user_list',
		component(){
			return import('@/pages/admin/staff/user_list.vue')
		}
	},{
		path:'admin/ug_list',
		component(){
			return import('@/pages/admin/staff/ug_list.vue')
		}
	},{
		path:'admin/staff_act_record',
		component(){
			return import('@/pages/admin/staff/staff_act_record.vue')
		}
	}
]