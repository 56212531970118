/* 各种校验 */
const check = {
	is_num:function(num){
		var re=/^[0-9]+.?[0-9]*$/;
		return re.test(num);
	},
	obj_length:function(obj){
		if(!this.is_obj(obj)){
			return 0;
		}
		let len=0;
		for(let i in obj){
			len++;
		}
		return len;
	},
	is_obj:function(obj){
		if(typeof(obj)!='object'){
			return false;
		}else{
			if(Array.isArray(obj)){
				return false;
			}else{
				return true;
			}
		}
	},
	is_arr:function(arr){
		return Array.isArray(arr)
	},
	is_str:function(str){return (typeof(str)=='string')},
	is_fun:function(fun){return (typeof fun === "function")},
	is_bl:function(num){//判断是否是营业执照号码
		var reg=/^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$/;
		return reg.test(num)
	},
	is_bank_card:function(num){//判断是否是银行卡号
		var reg=/^([1-9]{1})(\d{14}|\d{18}|\d{17}|\d{22})$/;
		return reg.test(num)
	},
	id_card_num:function(num){//判断是否是身份证号码
		var reg=/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
		return reg.test(num)
	},
	is_post:function(num){//判断是否是邮政编码
		var reg=/^[1-9][0-9]{5}$/;
		return reg.test(num)
	},
	is_tel:function(tel){//判断是否是电话号码
		var reg=/^1(3|4|5|6|7|8|9)\d{9}$/;
		return reg.test(tel)
	},
	is_email:function(str){//判断是否是邮箱地址
		var reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
		return reg.test(str);
	},
	is_name:function(str){ //判断姓名格式是否正确
		var reg =/^[\u4e00-\u9fa5]{2,10}$/;
		return reg.test(str)
	},
	is_plate_num:function(str){//判断是否是车牌号
		var reg = /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新使]{1}[A-Z]{1}[0-9a-zA-Z]{5}$/u;
		return reg.test(str);
	},
	is_vin_code:function(str){//判断是否是车架号
		var reg = /^[A-HJ-NPR-Z\d]{17}$/;
		return reg.test(str);
	},
	is_lat:function(str){//判断是否是纬度
		var reg = /^[\-\+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?)$/;
		return reg.test(str);
	},
	is_lng:function(str){//判断是否是经度
		var reg = /^[\-\+]?(0(\.\d{1,10})?|([1-9](\d)?)(\.\d{1,10})?|1[0-7]\d{1}(\.\d{1,10})?|180\.0{1,10})$/;
		return reg.test(str);
	},
}

export default check