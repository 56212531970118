//app用户 的页面路由
export default [{
		path:'app_user/user_list',
		component(){
			return import('@/pages/app_user/user_list.vue')
		}
	},{
		path:'app_user/company_list',
		component(){
			return import('@/pages/app_user/company_list.vue')
		}
	},{
		path:'app_user/app_user_bankcard',
		component(){
			return import('@/pages/app_user/app_user_bankcard.vue')
		}
	}
]