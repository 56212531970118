//汽运客户 路由
export default [
	
	//汽运客户相关
	{
		path:'transit_customer/shipper_extend',
		component(){
			return import('@/pages/transit_customer/customer/extend.vue')
		}
	},
	{
		path:'transit_customer/customer_list',
		component(){
			return import('@/pages/transit_customer/customer/list.vue')
		}
	},


	//客户线路相关
	{
		path:'transit_customer/business_line_list',
		component(){
			return import('@/pages/transit_customer/business_line/list.vue')
		}
	},
]