//运单相关 路由
export default [

	//录入事件
	{
		path:'transit_after_time/event_list',
		component(){
			return import('@/pages/transit_after_time/event/event_list.vue')
		}
	},


	//开票相关
	{
		path:'transit_after_time/invoice_apply',
		component(){
			return import('@/pages/transit_after_time/invoice/invoice_apply.vue')
		}
	},{
		path:'transit_after_time/invoice_done',
		component(){
			return import('@/pages/transit_after_time/invoice/invoice_done.vue')
		}
	},{
		path:'transit_after_time/invoice_revoke',
		component(){
			return import('@/pages/transit_after_time/invoice/invoice_revoke.vue')
		}
	},


	//运单相关
	{
		path:'transit_after_time/tord_before_check',
		component(){
			return import('@/pages/transit_after_time/tord/tord_before_check.vue')
		}
	},{
		path:'transit_after_time/tord_check_pass',
		component(){
			return import('@/pages/transit_after_time/tord/tord_check_pass.vue')
		}
	},{
		path:'transit_after_time/tord_check_unpass',
		component(){
			return import('@/pages/transit_after_time/tord/tord_check_unpass.vue')
		}
	},
]